.section__Jobs-container {
    margin-top: 50px;
    max-width: 100%;
    position: relative;
  }
  
  .section__Jobs-container span {
    border-left: 2px solid#64ffda;
    font-size: 12px;
    z-index: 10;
    position: absolute;
    top: 0;
    left: 15px;
    display: inline-block;
    width: 2px;
    height: 42px;
    transition: transform 0.5s;
  }

  .the_container{
      display: flex;
      /* justify-content: space-between; */
  }

  .the_first{
      width: 30%;
  }

  .the_second{
    width: 70%;
}
  
  .section__Jobs-container span.index1-chosen {
    transform: translateY(0px);
  }
  .section__Jobs-container span.index2-chosen {
    transform: translateY(42px);
  }
  .section__Jobs-container span.index3-chosen {
    transform: translateY(84px);
  }
  
  .section__Jobs-styledTabList {
    max-width: 100%;
  }
  
  .section__Jobs-styledTabList {
    padding: 0;
  }
  