.section__Jobs-buttonCompany {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    /* border-left: 2px solid #8892b0; */
    outline: none;
    font-family: "Roboto Mono", monospace;
    font-size: 13px;
    height: 42px;
    width: 90%;
    text-align: left;
    padding: 0px 20px 20px 20px;
  }
  
  .section__Jobs-buttonCompany:hover,
  .section__Jobs-buttonCompany:focus {
    background-color: #172a45;
  }
  
  .section__Jobs-buttonCompany:hover,
  .section__Jobs-buttonCompany:active,
  .section__Jobs-buttonCompany:focus {
    color: #64ffda;
    outline: 0px;
  }
  